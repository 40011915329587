var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-box" },
    [
      _c("i-header", {
        attrs: { title: _vm.titleArr[_vm.$route.query.type] },
        on: { "back-event": _vm.toBack },
      }),
      _c(
        "div",
        { staticClass: "cnt-box" },
        [
          _c(
            "i-form",
            {
              ref: "form",
              attrs: { "scroll-to-error": "" },
              on: { submit: _vm.onSubmit },
            },
            [
              _vm._l(_vm.template[0], function (item, index) {
                return _c("iFormItem", {
                  key: index,
                  attrs: {
                    "model-val": _vm.formData[item.controlName],
                    "form-item": item,
                  },
                })
              }),
              _c("br"),
              _c(
                "i-button",
                {
                  attrs: {
                    loading: _vm.loading,
                    disabled: _vm.loading,
                    "loading-text": "提交",
                    block: "",
                    round: "",
                    type: "primary",
                    size: "normal",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.isPfizer ? "保存" : "提交"))]
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }