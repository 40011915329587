<template>
    <div class="add-box">
        <i-header :title="titleArr[$route.query.type]" @back-event="toBack"></i-header>
        <div class="cnt-box">
            <i-form ref="form" @submit="onSubmit" scroll-to-error>
                <iFormItem v-for="(item, index) in template[0]" :key="index" :model-val="formData[item.controlName]" :form-item="item"></iFormItem>
                <br />
                <i-button :loading="loading" :disabled="loading" loading-text="提交" block round type="primary" size="normal"> {{isPfizer ? '保存':'提交' }}</i-button>
            </i-form>
        </div>
    </div>
</template>

<script>
import iFormItem from "@/components/iFormItem/iFormItem";
import {getCookie} from "tiny-cookie";
import timeDiff from "@/utils/filter/timeDifference";

export default {
    name: "FuncAdd",
    components: {
        iFormItem,
    },
    data() {
        return {
            isPfizer: getCookie('tenant') === 'pfizer',
            loading: false,
            titleArr: {
                Attendee: "参会人",
                Speaker: "讲者",
                MiceInfo: "参会信息"
            },
            template: [],
            formData: {},
        };
    },
    created() {
        const directoryId = this.$cookie.get("tenant_id") || this.$smartStorage.get("tenant_id");
        if (directoryId) {
            this.queryTemplate(directoryId);
        }
    },
    methods: {
        toBack() {
            this.$router.back();
        },
        toSubmit() {
            this.$refs.form.submit();
        },
        onSubmit(values) {
            let params = {},
                actions = "AddDoc";
            // 排除选择的原始值
            for (const valKey in values) {
                if (valKey == "undefined") delete values[valKey];
                if (values[valKey + "SourceData"] !== undefined) {
                    delete values[valKey + "SourceData"];
                }
                if (valKey == "participantsNumber") values[valKey] = Number(values[valKey]);
            }

            if (this.$route.query.isEdit == 1) {
                if(this.$route.query.type === 'MiceInfo'){ // 辉瑞的编辑会议信息
                    this.savePfizerEdit(values);
                    return
                }
                actions = "EditDoc";
                let data = this.$store.state.funcItemInfo;
                params = {
                    Updater: values,
                    Query: {
                        DocId: data.DocId,
                    },
                };
            } else {
                params = {
                    Doc: Object.assign(values, {
                        Catalog: this.$route.query.type,
                        MiceId: this.$route.query.miceId,
                    }),
                };
            }

            this.loading = true;
            this.$service[actions](params).then((res) => {
                this.loading = false;
                if (res && res.success) {
                    this.$itoast.success(this.$route.query.isEdit == 1 ? "编辑成功" : "添加成功");
                    this.toBack();
                }
            });
        },
        // 辉瑞保存参会信息
        savePfizerEdit(values){
            // 前置校验
            // 1，结束时间大于开始时间
            if(values.dtStartTime && values.dtEndTime){
                const diff = timeDiff(values.dtStartTime)(values.dtEndTime);
                if(diff >= 0) {
                    this.$toast.fail('结束时间不能小于开始时间');
                    return;
                }
            }
            // 2，内部参会人数不能为0
            console.log(values.qtyAttenderExt);
            if(!parseInt(values.qtyAttenderInt) || !parseInt(values.qtyAttenderExt)){
                this.$toast.fail('内部或外部参会人数需要大于0');
                return;
            }
            // 3. 时间加上:00
            if(values.dtStartTime.length < 8){
                values.dtStartTime = values.dtStartTime + ':00';
            }
            if(values.dtEndTime.length < 8){
                values.dtEndTime = values.dtEndTime + ':00';
            }
            const params = {...this.$store.state.funcItemInfo, ...values};
            this.$service.SaveConferenceInformationsV2(params).then(res => {
                if(res.success){
                    this.$toast.success('成功');
                    this.$router.go(-1)
                }
            })
        },
        // 得出时间差（不包含日期）
        compareTimeDiff(time1){
            return function (time2) {
                const handler = (params) => {
                    return params.replace(/:/g, '').length === 6 ? parseInt(params.replace(/:/g, '')) / 100 : params.replace(/:/g, '');
                }
                return handler(time1) - handler(time2);
            }
        },
        async queryTemplate(directoryId) {
            let templateCode;
            switch (this.$route.query.type) {
                case "Attendee":
                    templateCode = "AddParticipantsNumber";
                    break;
                case "MiceInfo": // 辉瑞的参会信息
                    templateCode = "PfizerMeetingInfo";
                    break;
                default:
                    templateCode = "AddSpeaker";
                    break;
            }

            const params = {
                collection: "cfg-crossevent-ui-template",
                filter: {
                    module: "meeting",
                    templateCode,
                    directoryId: directoryId,
                    roles: {
                        $in: ["*"],
                    },
                },
                projection: {
                    _id: 0,
                    template: 1,
                },
            };
            const res = await this.$service.GetSettings(params);

            if (res && res.success && res.content) {
                console.log("====表单模板", res.content.template);
                res.content.template.map((element) => {
                    element.map((item) => {
                        if (item.controlName) {
                            this.$set(this.formData, item.controlName, "");
                        }
                        return item;
                    });
                    return element;
                });

                this.template = res.content.template;
                if (this.$route.query.isEdit == 1) {
                    this.editData();
                }
            }
        },
        editData() {
            let data = this.$store.state.funcItemInfo;
            console.log("====旧表单项值", this.$store.state.funcItemInfo);
            for (const Key in this.formData) {
                // console.log(Key, this.formData);
                this.formData[Key] = data[Key];
            }
        },
    },
};
</script>

<style lang="less" scoped>
.add-box {
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    .cnt-box {
        padding: 0 0.25rem;
        box-sizing: border-box;
    }
    .submit-btn {
        width: 88%;
        height: 0.45rem;
        line-height: 0.45rem;
        border-radius: 0.3rem;
        margin: 11.73vw auto 0 auto;
        font-size: 0.15rem;
        background: var(--themeColor);
        display: block;
        color: #fff;
        text-align: center;
    }
}
</style>
